<template>
  <div class="course-add">
    <el-form ref="courseForm" :model="form" :rules="rules" label-width="200px" label-position="left">
      <el-form-item label="请输入课程名称" prop="courseName">
        <el-input v-model="form.courseName"></el-input>
      </el-form-item>
      <el-form-item prop="video" label="视屏">
        <el-upload
            class="upload-demo"
            :on-success="success"
            :on-remove="remove"
            :before-upload="beforeUpload"
            drag
            :show-file-list="false"
            :file-list="fileList"
            :action=vodUrl
            multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>,只能上传mp4格式</div>
          <div class="el-upload__tip" slot="tip">
            <el-table
                :data="fileList"
                v-if="fileList"
                border
                style="width: 100%">
              <el-table-column
                  prop="name"
                  label="名称"
              >
              </el-table-column>
              <el-table-column
                  prop="size"
                  label="大小kb"
                  width="150">
              </el-table-column>
              <el-table-column
                  fixed="right"
                  label="操作"
                  width="100">
                <template slot-scope="scope">
                  <el-button @click="deleteVideo(scope.row)" type="text" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

        </el-upload>
      </el-form-item>
      <el-form-item label="请选择分区" prop="courseClassification">
        <el-select v-model="form.courseClassification" placeholder="请选择视频分区">
          <el-option label="考研" value="1"></el-option>
          <el-option label="健身" value="2"></el-option>
          <el-option label="娱乐" value="3"></el-option>
          <el-option label="影视" value="4"></el-option>
          <el-option label="舞蹈" value="5"></el-option>
          <el-option label="鬼畜" value="6"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程封面" class="h" prop="courseUrl">
        <div>
          <el-upload
              class="avatar-uploader"
              :action=ossUrl
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <el-avatar shape="square" v-if="form.courseUrl" :src="form.courseUrl" class="avatar img">
            </el-avatar>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </el-form-item>

      <el-form-item label="请选择标签" prop="tags">
        <el-tag
            :key="tag"
            v-for="tag in form.tags"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)" class="tag">
          {{ tag }}
        </el-tag>
        <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
      </el-form-item>

      <el-form-item label="资源类型" prop="courseType">
        <el-radio-group v-model="form.courseType">
          <el-radio label="1">自制</el-radio>
          <el-radio label="2">转载</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="简介" prop="courseIntroduction">
        <el-input type="textarea" v-model="form.courseIntroduction"></el-input>
      </el-form-item>


      <el-form-item>
        <el-button type="primary" @click="onSubmit">添加课程信息</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {addCourse} from "@/api/course"
import Global from "@/config/global";

export default {
  name: "add",
  data() {
    return {
      fileList: [],
      inputVisible: false,
      inputValue: '',
      vodUrl: Global.vodUrl,
      ossUrl: Global.uploadUrl,
      form: {
        userId: this.$store.state.user.id,
        courseName: '',
        courseType: "",
        courseClassification: '',
        courseUrl: '',
        courseIntroduction: "",
        video: [],
        tags: [],
      },
      file: [],
      loading: {},
      rules: {
        courseUrl: [{required: true, message: '请添加封面', trigger: 'blur'}],
        courseName: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
        ],
        courseType: [
          {required: true, message: '请输入资源类型', trigger: 'change'}
        ],
        courseClassification: [
          {required: true, message: '请至少选择一个分区', trigger: 'change'}
        ],
        courseIntroduction: [
          {required: true, message: '请填写活动简介', trigger: 'blur'}
        ],
        tags: [
          {type: 'array', required: true, message: '请至少添加一个标签', trigger: 'blur'}
        ],
        video: [
          {type: 'array', required: true, message: "请至少添加一个视屏", trigger: 'change'}
        ],
      }

    }
  },
  methods: {
    beforeUpload(file) {
      console.log(file)
      const isMp4 = file.type === 'video/mp4';
      if (isMp4) {
        this.loading = this.$loading({
          lock: true,
          text: '正在上传',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      } else {
        this.$message.error("请上传mp4格式的视频")
      }
      return isMp4
    },
    deleteVideo(file) {
      let that = this;
      this.$confirm(`确定移除 ${file.name}？`, '删除文件', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let index = that.fileList.indexOf(file);
        if (index > -1) {
          that.fileList.splice(index, 1);
          // 删除过后
          console.log("删除过后")
          console.log(that.fileList)
        }
        this.$message({
          type: 'success',
          message: '删除成功'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeAvatarUpload() {

    },
    // 课程添加成功
    onSubmit() {
      this.$refs['courseForm'].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "请填写数据",
            type: "error",
            duration: 2 * 1000
          })
          return false;
        } else {
          let temp = {
            userId: this.$store.state.user.id,
            courseName: this.form.courseName,
            courseType: parseInt(this.form.courseType),
            courseClassification: parseInt(this.form.courseClassification),
            courseUrl: this.form.courseUrl,
            courseIntroduction: this.form.courseIntroduction,
            video: this.form.video,
            tags: this.form.tags.join(","),
          }
          console.log(temp);
          addCourse(temp).then(result => {
            this.$message({
              type: 'success',
              message: result.data.course,
              duration: 2 * 1000
            })
            // this.$router.push("/self/showCourse")
          }).catch(error => {
            this.$message(error.message)
          })
        }
      });
    },
    handleClose(tag) {
      this.form.tags.splice(this.form.tags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.form.tags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      this.form.courseUrl = res.data.url;
    },
    remove(file, fileList) {
      // console.log(fileList)
      // this.fileList = fileList;
      // this.fileList
      // console.log(file)
    },
    // 上传视屏成功视频信息
    success(response, file, fileList) {
      this.loading.close();
      if (response.code === 20000) {
        this.fileList = fileList;
        console.log(this.fileList)
        this.$message({
          type: "success",
          message: "视频上传成功"
        })
      } else {
        this.$message({
          type: "error",
          message: response.data.message
        })
      }
    }
  },
  watch: {
    fileList() {
      // console.log(this.fileList)
      this.form.video = []
      this.fileList.forEach(item => {
        if (item.status === "success" && item.response.code === 20000) {
          this.form.video.push(item.response.data.videoId)
        }
      })
    }
  }
}
</script>

<style scoped>
.course-add {
  background-color: #ffffff;
  width: 85%;
  margin: 10px auto;
  padding: 10px;
}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 11.125rem;
  margin: auto;
}

.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: auto;
  width: 11.125rem;
}

.avatar-uploader :hover {
  border-color: #409EFF;
}

.el-upload :hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 1.75rem;
  color: #8c939d;
  width: 11.125rem;
  height: 11.125rem;
  line-height: 11.125rem;
  text-align: center;
}

.h {
  height: 12.5rem;
}

.img {
  width: 11.125rem;
  height: 11.125rem;
  display: block;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
